//React
import React from 'react'

//Icons
import { MdPeople, MdSearch } from 'react-icons/md'
import { BiBullseye, BiExport } from 'react-icons/bi'
import { RiSmartphoneLine, RiContrastFill } from 'react-icons/ri'
import { AiOutlineEdit } from 'react-icons/ai'
import { IoImagesOutline } from 'react-icons/io5'

const Snippet = (props) => (
  <>
    <div class="pricing-feature">
      <div class="pricing-feature-icon d-none d-md-block">
        <MdPeople size={22} />
      </div>
      <div class="pricing-feature-text">
        Social template discovery: Find templates &amp; follow designers
      </div>
    </div>
    {/* <div class="pricing-feature">
      <div class="pricing-feature-icon d-none d-md-block">
        <MdSearch size={22} />
      </div>
      <div class="pricing-feature-text">Search and load design templates </div>
    </div> */}
    <div class="pricing-feature">
      <div class="pricing-feature-icon d-none d-md-block">
        <BiBullseye size={22} />
      </div>
      <div class="pricing-feature-text">
        Hyper-relevant content for your industry
      </div>
    </div>
    <div class="pricing-feature">
      <div class="pricing-feature-icon d-none d-md-block">
        <RiSmartphoneLine size={22} />
      </div>
      <div class="pricing-feature-text">
        Mobile, tablet &amp; desktop authoring - no app required
      </div>
    </div>
    <div class="pricing-feature">
      <div class="pricing-feature-icon d-none d-md-block">
        <AiOutlineEdit size={22} />
      </div>
      <div class="pricing-feature-text">
        Customize template elements &amp; fonts
      </div>
    </div>
    <div class="pricing-feature">
      <div class="pricing-feature-icon d-none d-md-block">
        <IoImagesOutline size={22} />
      </div>
      <div class="pricing-feature-text">
        Upload photos to brand &amp; user libraries
      </div>
    </div>
    <div class="pricing-feature">
      <div class="pricing-feature-icon d-none d-md-block">
        <BiExport size={22} />
      </div>
      <div class="pricing-feature-text">Export images for sharing</div>
    </div>
    <div class="pricing-feature">
      <div class="pricing-feature-icon d-none d-md-block">
        <RiContrastFill size={22} />
      </div>
      <div class="pricing-feature-text">Dark &amp; light modes</div>
    </div>
  </>
)

export default Snippet
