//React
import React from 'react'
import { connect } from 'react-redux'

//Actions
import { saveLead } from '../../actions/saveLead'

//Packages
import parse from 'url-parse'

//Icons
import { FaSpinner } from 'react-icons/fa'

//Redux
const mapStateToProps = ({ leadsubmitted, isLoading }) => {
  return { leadsubmitted, isLoading }
}
const mapDispatchToProps = (dispatch) => ({
  saveLead: (userData) => {
    dispatch(saveLead(userData))
  },
})

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userName: '',
      userLastName: '',
      userTitle: '',
      userCompany: '',
      userEmail: '',
      userPhone: '',
      userEmployees: '',
      leadSource: '',
      campaignId: '',
      initialLoad: true,
      submitted: false,
      submitComplete: false,
      error: {
        userName: false,
        userLastName: false,
        userCompany: false,
        userEmail: false,
        userPhone: false,
        userEmployees: false,
      },
    }
  }

  componentDidMount() {
    if (this.props.leadSource) {
      console.log(`**leadsource ${this.props.leadSource}`)
      this.setState({
        leadSource: `${this.props.leadSource}`,
      })
    }
    const url = parse(window.location.href, true)

    if (url.query && url.query.c) {
      this.setState({ campaignId: url.query.c })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.leadSource !== this.props.leadSource) {
      this.setState({
        leadSource: `${this.props.leadSource}`,
      })
    }
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
      initialLoad: false,
    })
  }

  sendConversionEvent() {
    if (
      typeof window !== `undefined` &&
      process.env.STAGE !== 'dev' &&
      process.env.STAGE !== 'staging'
    ) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-615742954/GdUSCK7N9NYBEOr7zaUC',
      })
    }
  }

  handleValidation(event) {
    event.preventDefault()
    event.stopPropagation()

    const formData = this.state
    const context = this.props

    console.log('**formData')
    console.log(formData)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/
    const validEmail = emailRegex.test(formData.userEmail)

    let errorObj = {
      userName: false,
      userLastName: false,
      userCompany: false,
      userEmail: false,
      userPhone: false,
      userEmployees: false,
    }
    if (!formData.userName) {
      // this.setError('userName')
      console.log('no name')
      errorObj.userName = true
    }
    if (!formData.userLastName && !context) {
      // this.setError('userLastName')
      errorObj.userLastName = true

      // return
    }
    if (!formData.userCompany) {
      // this.setError('userCompany')
      errorObj.userCompany = true

      // return
    }
    if (!formData.userEmail || validEmail !== true) {
      // this.setError('userEmail')
      errorObj.userEmail = true

      // return
    }
    if (!formData.userPhone && !context) {
      // this.setError('userPhone')
      errorObj.userPhone = true
      // return
    }
    // if (!formData.userEmployees && !context) {
    //   // this.setError('userEmployees')
    //   errorObj.userEmployees = true
    //   // return
    // }
    console.log('errorObj')
    console.log(errorObj)
    console.log('this.state.error')
    console.log(this.state.error)

    this.setState({ error: errorObj })

    if (
      this.state.initialLoad === false &&
      Object.values(errorObj).every((value) => value === false)
    ) {
      console.log('**handline submit**')
      this.handleSubmit()
      setTimeout(() => {
        this.setState({
          userName: '',
          userLastName: '',
          userTitle: '',
          userCompany: '',
          userEmail: '',
          userPhone: '',
          userEmployees: '',
          // leadSource: '',
          // campaignId:'',
          initialLoad: true,
          submitted: false,
          submitComplete: false,
          error: {
            userName: false,
            userLastName: false,
            userCompany: false,
            userEmail: false,
            userPhone: false,
            userEmployees: false,
          },
        })
      }, 5000)
    }
  }
  handleSubmit = () => {
    const formData = this.state

    console.log('formData')
    console.log(formData)

    this.sendConversionEvent()
    this.props.saveLead(formData)
  }
  render() {
    const { isLoading, leadsubmitted, context, image } = this.props

    return (
      <>
        <div
          class={`gradient-callout-algae ${
            context && context === 'creative' ? 'rounded-corners' : ''
          }`}
        >
          <div class="container p-4">
            <div class="row">
              <div class="col-lg-12 d-flex align-items-center justify-content-center text-center">
                <h1 class="display-2 font-weight-normal white-1">
                  {this.props.title
                    ? this.props.title
                    : 'Create content, socially'}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div class="container pb-5">
          <div class="row text-center">
            <div
              class={
                context && context === 'creative'
                  ? 'col-lg-12'
                  : 'col-lg-8 offset-lg-2'
              }
            >
              {leadsubmitted === false ? (
                <div class="p-4">
                  {image ? (
                    <img src={image} class="rounded-corners" width="200" />
                  ) : null}
                  <h2 class="display-4 my-3 p-2">
                    {this.props.subtitle
                      ? this.props.subtitle
                      : "We're sending invites to Storylava constantly. Request yours below."}
                  </h2>

                  <div class="form-group">
                    <label for="userName" class="d-none">
                      {context === 'creative' ? 'Name' : 'First name'}
                    </label>

                    <input
                      type="text"
                      class={`form-control form-control-lg ${
                        this.state.error.userName ? 'is-invalid' : ''
                      }`}
                      name="userName"
                      id="userName"
                      placeholder={
                        context === 'creative' ? 'Name' : 'First name'
                      }
                      value={this.state.userName}
                      onChange={this.handleInputChange}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          this.handleValidation(e)
                        }
                      }}
                    />
                  </div>
                  {!context ? (
                    <div class="form-group">
                      <label for="userLastName" class="d-none">
                        Last name
                      </label>
                      <input
                        type="text"
                        class={`form-control form-control-lg ${
                          this.state.error.userLastName ? 'is-invalid' : ''
                        }`}
                        name="userLastName"
                        id="userLastName"
                        placeholder="Last name"
                        value={this.state.userLastName}
                        onChange={this.handleInputChange}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            this.handleValidation(e)
                          }
                        }}
                      />
                    </div>
                  ) : null}
                  {/* <div class="form-group">
                      <label for="userTitle" class="d-none">
                        Job title
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="userTitle"
                        id="userTitle"
                        placeholder="Job title"
                        value={this.state.userTitle}
                        onChange={this.handleInputChange}
                      />
                    </div> */}
                  <div class="form-group">
                    <label for="userCompany" class="d-none">
                      Organization name
                    </label>
                    <input
                      type="text"
                      // class="form-control form-control-lg"
                      class={`form-control form-control-lg ${
                        this.state.error.userCompany ? 'is-invalid' : ''
                      }`}
                      name="userCompany"
                      id="userCompany"
                      placeholder="Organization name"
                      value={this.state.userCompany}
                      onChange={this.handleInputChange}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          this.handleValidation(e)
                        }
                      }}
                    />
                  </div>
                  <div class="form-group">
                    <label for="userEmail" class="d-none">
                      {context === 'creative' ? 'Email' : 'Business email'}
                    </label>
                    <input
                      type="text"
                      // class="form-control form-control-lg"
                      class={`form-control  form-control-lg ${
                        this.state.error.userEmail ? 'is-invalid' : ''
                      }`}
                      name="userEmail"
                      id="userEmail"
                      placeholder={
                        context === 'creative' ? 'Email' : 'Business email'
                      }
                      value={this.state.userEmail}
                      onChange={this.handleInputChange}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          this.handleValidation(e)
                        }
                      }}
                    />
                  </div>
                  {!context ? (
                    <div class="form-group">
                      <label for="userPhone" class="d-none">
                        Business phone{' '}
                      </label>
                      <input
                        type="text"
                        // class="form-control form-control-lg"
                        class={`form-control form-control-lg ${
                          this.state.error.userPhone ? 'is-invalid' : ''
                        }`}
                        name="userPhone"
                        id="userPhone"
                        placeholder="Business phone"
                        value={this.state.userPhone}
                        onChange={this.handleInputChange}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            this.handleValidation(e)
                          }
                        }}
                      />
                    </div>
                  ) : null}
                  {/* {!context ? (
                      <div class="form-group">
                        <label for="userEmployees" class="d-none">
                          Number of employees{' '}
                        </label>
                        <input
                          type="text"
                          // class="form-control form-control-lg"
                          class={`form-control form-control-lg ${
                            this.state.error.userEmployees ? 'is-invalid' : ''
                          }`}
                          name="userEmployees"
                          id="userEmployees"
                          placeholder="# of employees"
                          value={this.state.userEmployees}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    ) : null} */}
                  {/* <div class="form-group">
                      <label for="userComments" class="d-none">
                        Anything you'd like to tell us?{' '}
                      </label>
                      <textarea
                        class="form-control form-control-lg"
                        name="userComments"
                        id="userComments"
                        rows="3"
                        placeholder="Anything you'd like to tell us?"
                        onChange={this.handleInputChange}
                      />
                    </div> */}
                  <div class="py-3">
                    <button
                      type="button"
                      class="btn btn-cta-reverse btn-lg btn-block"
                      onClick={(e) => this.handleValidation(e)}
                    >
                      {this.props.cta ? this.props.cta : 'Send'}
                      {isLoading ? (
                        <FaSpinner
                          size={15}
                          className="icon-spin"
                          style={{ marginLeft: '10px' }}
                        />
                      ) : null}
                    </button>
                  </div>
                </div>
              ) : (
                <div class="p-4">
                  <h2 class="display-3 my-3 p-2">
                    Thanks! We'll be in touch shortly with you.
                  </h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
