import React, { useEffect, useState } from 'react'

import { Auth } from 'aws-amplify'

//Components
import Loading from '../loading'
import ContactLeadForm from '../marketing/contactLeadForm'

//Packages
import Helmet from 'react-helmet'

//Icons
import { FaTimes, FaPlus } from 'react-icons/fa'
import { BsFillPersonCheckFill, BsPersonPlus } from 'react-icons/bs'

const Snippet = (props) => {
  const { image, leadSource } = props

  return (
    <>
      {/* <Helmet>
        <script
          src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
          integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
          crossOrigin="anonymous"
        ></script>
        <script
          src="https://stackpath.bootstrapcdn.com/bootstrap/4.1.1/js/bootstrap.min.js"
          integrity="sha384-smHYKdLADwkXOn1EmN1qk/HfnUcbVRZyYmZ4qpPea6sjB/pTJ0euyQp0Mk8ck+5T"
          crossOrigin="anonymous"
        ></script>
      </Helmet> */}
      <div class="reporting-container w-100 p-4">
        <div class={`d-flex flex-column w-100`}>
          {/* {isLoadingTemplateCreate ? (
          <div class="w-100 p-4">
            <Loading class="small d-flex align-self-center" />
          </div>
        ) : null} */}
          <div class="d-flex flex-column">
            {/* <iframe
              src={`http${process.env.STAGE === 'dev' ? '' : 's'}://${
                process.env.HOSTNAME
              }/request-invitation/`}
            ></iframe> */}
            <ContactLeadForm
              context="creative"
              leadSource={leadSource ? leadSource : 'unknown'}
              image={image}
              title="Let's get started"
              subtitle="We're constantly releasing new invitations to Storylava — get yours below!"
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default Snippet
