import React, { useState } from 'react'
import { connect } from 'react-redux'

//Actions

//Components
import RequestInviteModalForm from './requestInviteModalForm'

//Icons

// const mapStateToProps = ({
//   publisher,
//   social,
//   isLoadingTemplateCreate,
//   userdetail,
// }) => {
//   return {
//     publisher,
//     social,
//     isLoadingTemplateCreate,
//     userdetail,
//   }
// }
// const mapDispatchToProps = (dispatch) => ({
//   socialModalClear: () => dispatch(socialModalClear()),
//   loadNewTemplate: (id, context) => dispatch(loadNewTemplate(id, context)),
//   createUserFollow: (mode, followid) =>
//     dispatch(createUserFollow(mode, followid)),
// })

const Snippet = (props) => {
  const { image, leadSource } = props

  return (
    <div
      class="modal modal-sharer modal-creative fade"
      id="requestInviteModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="requestInviteModal"
      aria-hidden="true"
      data-keyboard="false"
      // data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ borderBottom: 0, position: 'absolute', right: 0 }}
          >
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              // onClick={() => {
              //   socialModalClear()
              // }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {/* <div class="modal-subheader d-flex flex-row align-items-center justify-content-start py-3">
              <h4
                class="text-left pl-4 font-weight-bold white-1"
                style={{ paddingTop: 8, marginRight: 8 }}
              >
              </h4>
            </div> */}

            <div
              class="modal-content-container d-flex flex-column align-items-center justify-content-center"
              style={{ margin: 'auto' }}
            >
              <RequestInviteModalForm image={image} leadSource={leadSource} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Snippet
// export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
// export default connect(mapStateToProps, null)(Snippet)
