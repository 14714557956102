//React
import React, { useState, useEffect } from 'react'

//Components
import RequestInviteModal from '../elements/requestInviteModal'

//Icons
import FeatureIcon from './featureIcon'
import PriceComparisonFeaturesBase from './priceComparisonFeaturesBase'
import PricingStar from '../../images/svg/pricing/pricingicon-star.svg'
import PricingRocket from '../../images/svg/pricing/pricingicon-rocket.svg'
import PricingDiamond from '../../images/svg/pricing/pricingicon-diamond.svg'

import { FaArrowRight } from 'react-icons/fa'
import { RiLayoutRight2Line, RiShareForwardFill } from 'react-icons/ri'
import { TiCloudStorage } from 'react-icons/ti'

const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  return windowSize
}

const Snippet = (props) => {
  const size = useWindowSize()
  const [selectedPlan, setSelectedPlan] = useState('')
  return (
    <>
      <div class="container py-4">
        <div class="row d-flex flex-row justify-content-center align-items-stretch">
          <div class="pricing-col pricing-col-1 col-md-4 round-corners-top border-1-gray-7 pb-2 d-flex flex-column justify-content-start align-items-center">
            <div class="pricing-mobile-gradient round-corners-top gradient-callout-greensky"></div>
            <div class="p-2 d-flex flex-row flex-md-column align-items-center">
              <div class="pricing-icon">
                <PricingStar />
              </div>
              <div class="pricing-package-container d-flex flex-column">
                <div class="pricing-package text-center sky-1">Basic</div>
                <hr class="d-none d-md-block w-100" size="1" />
                <div class="pricing-price-container d-flex flex-column align-items-stretch justify-content-center">
                  <div class="pricing-price text-center sky-1">Free</div>
                </div>
              </div>
            </div>
            <div class="pricing-cta">
              <button
                data-toggle="modal"
                data-target="#requestInviteModal"
                class="btn btn-outline-sky mt-2"
                onClick={() => {
                  setSelectedPlan('basic')
                }}
              >
                Get started <FaArrowRight />
              </button>
            </div>

            <hr class="w-100" size="1" />

            <div class="pricing-features">
              <div class="pricing-feature">
                <div class="pricing-feature-icon">
                  <RiLayoutRight2Line size={22} />
                </div>
                <div class="pricing-feature-text">Save public templates</div>
              </div>
              <div class="pricing-feature">
                <div class="pricing-feature-icon">
                  <TiCloudStorage size={22} />
                </div>
                <div class="pricing-feature-text">500MB image storage</div>
              </div>
              <div class="pricing-feature">
                <div class="pricing-feature-icon">
                  <RiShareForwardFill size={22} />
                </div>
                <div class="pricing-feature-text">Download images</div>
              </div>
            </div>
          </div>

          {/* <div class="pricing-col pricing-col-2 col-md-3 round-corners-top border-1-gray-7 pb-2  d-flex flex-column justify-content-start align-items-center">
            <div class="pricing-mobile-gradient round-corners-top gradient-callout-tropical"></div>

            <div class="p-2 d-flex flex-row flex-md-column align-items-center">
              <div class="pricing-icon">
                <PricingDiamond />
              </div>
              <div class="pricing-package-container d-flex flex-column">
                <div class="pricing-package text-center dkblue-1">Pro</div>
                <hr class="d-none d-md-block w-100" size="1" />
                <div class="pricing-price-container d-flex flex-column align-items-stretch justify-content-center">
                  <div class="pricing-price text-center dkblue-1">
                    $7
                    <div class="pricing-price-term gray-2">per month/user</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="pricing-cta">
              <button
                data-toggle="modal"
                data-target="#requestInviteModal"
                class="btn btn-outline-primary mt-2"
                onClick={() => {
                  setSelectedPlan('pro')
                }}
              >
                Get started <FaArrowRight />
              </button>
            </div>
            <hr class="w-100" size="1" />

            <div class="pricing-features">
              <div class="pricing-feature">
                <div class="pricing-feature-icon">
                  <RiLayoutRight2Line size={22} />
                </div>
                <div class="pricing-feature-text">Save public templates</div>
              </div>
              <div class="pricing-feature">
                <div class="pricing-feature-icon">
                  <TiCloudStorage size={22} />
                </div>
                <div class="pricing-feature-text">20GB image storage</div>
              </div>
              <div class="pricing-feature">
                <div class="pricing-feature-icon">
                  <RiShareForwardFill size={22} />
                </div>
                <div class="pricing-feature-text">Download images</div>
              </div>
            </div>
          </div> */}

          <div class="pricing-col pricing-col-3 col-md-4 round-corners-top border-1-gray-7 pb-2  d-flex flex-column justify-content-start align-items-center">
            <div class="pricing-mobile-gradient round-corners-top gradient-callout-fire"></div>

            <div class="p-2 d-flex flex-row flex-md-column align-items-center align-items-md">
              <div class="pricing-icon">
                <PricingRocket />
              </div>

              <div class="pricing-package-container d-flex flex-column ">
                <div class="pricing-package text-center dkorange-1">Pro</div>
                <hr class="d-none d-md-block w-100" size="1" />
                <div class="pricing-price-container d-flex flex-column align-items-stretch justify-content-center">
                  <div class="pricing-price text-center dkorange-1">
                    $15
                    <div class="pricing-price-term gray-2">per month/user</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="pricing-cta">
              <button
                data-toggle="modal"
                data-target="#requestInviteModal"
                class="btn btn-outline-dkorange mt-2"
                onClick={() => {
                  setSelectedPlan('proplus')
                }}
              >
                Get started <FaArrowRight />
              </button>
            </div>

            <hr class="w-100" size="1" />

            <div class="pricing-features">
              <div class="pricing-feature">
                <div class="pricing-feature-icon">
                  <RiLayoutRight2Line size={22} />
                </div>
                <div class="pricing-feature-text">
                  Save public &amp; private templates
                </div>
              </div>
              <div class="pricing-feature">
                <div class="pricing-feature-icon">
                  <TiCloudStorage size={22} />
                </div>
                <div class="pricing-feature-text">50GB image storage</div>
              </div>
              <div class="pricing-feature">
                <div class="pricing-feature-icon">
                  <RiShareForwardFill size={22} />
                </div>
                <div class="pricing-feature-text">
                  Download images &amp; share with one-click
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pricing-features-common-container row d-flex flex-row justify-content-center align-items-start">
          <div class="pricing-features-common col-md-8 round-corners-bottom border-1-gray-7 bg-sky-6">
            <div class="pricing-features-common-title small gradient-callout-cousteau py-1 px-2 white-1">
              Features available in all plans
            </div>
            <div class="pricing-features p-2">
              <PriceComparisonFeaturesBase />
            </div>
          </div>
        </div>
      </div>
      <RequestInviteModal campaignid={`pricing-${selectedPlan}`} />
    </>
  )
}

export default Snippet
