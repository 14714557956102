import 'babel-polyfill'
import 'isomorphic-fetch'
import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'

import Img from 'gatsby-image'
import Layout from '../../components/layout'
import DividerMid from '../../components/elements/dividerMid'
import PreviewVideo from '../../components/elements/previewVideo'
import FeatureIcon from '../../components/marketing/featureIcon'
import AlternatingCallout from '../../components/marketing/alternatingCallout'
import PlatformCallout from '../../components/marketing/platformCallout'
import FeatureQuad from '../../components/marketing/featureQuad'
import PriceComparison from '../../components/marketing/priceComparison'

import NavSubAbout from '../../components/nav/navSubAbout'

const About = (props) => (
  <Layout>
    <Helmet
      title="Storylava - Pricing - Visual Selling"
      meta={[
        {
          name: 'description',
          content:
            'See pricing plans for social content creation using Storylava',
        },
      ]}
    />

    <div class="gradient-callout-cousteau">
      <div class="container container-page my-0 pt-5 pb-5">
        <div class="row">
          <div class="col-12 d-flex flex-column justify-content-center text-center text-lg-center">
            <h2 class="font-weight-normal white-1 display-2">
              Simple and flexible plans to get started
            </h2>
          </div>
        </div>
      </div>
    </div>

    <PriceComparison />
    {/* 
    <div class="gradient-callout-algae">
      <div class="container container-page my-4 py-4">
        <div class="row d-flex flex-row align-items-center justify-content-center">
          <div class="col-lg-6 d-flex flex-column justify-content-center text-center text-lg-center">
            <h2 class="font-weight-medium white-1 py-3 display-3">
              Discover features
            </h2>
            <p class="display-4 white-1">
              Learn all about the features of the Storylava visual selling
              platform
            </p>
            <Link to="/about/features/" class="btn btn-cta-outline mt-2 mb-4">
              See features <FaArrowRight class="btn-arrow" />
            </Link>
          </div>
        </div>
      </div>
    </div> */}
  </Layout>
)

export default About

export const pageQuery = graphql`
  query {
    jumbotron: file(relativePath: { eq: "poly/electric.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1800, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
